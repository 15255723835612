import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import AmfSeguridadFooter from '../Assets/images/AmfSeguridadFooter.png';
import CertificacionesFooter from '../Assets/images/CertificacionesFooter.png';

const Footer = () => {
  return (
    <footer className="bottom-0 bg-blue-900 text-white p-12 flex-grow">
      <div className="flex justify-between items-center">
        <div>
          <img className="h-full w-full object-cover" src={CertificacionesFooter} alt="CertificacionesFooter" />
        </div>

        <div>
          <img className="h-full w-full object-cover" src={AmfSeguridadFooter} alt="AmfSeguridadFooter" />
        </div>

        <div>
          <div className="flex items-center mb-2">
            <FaMapMarkerAlt className="mr-2" />
            <span> Avenida Quilín 3700, Macul, Santiago de Chile</span>
          </div>
          <div className="flex items-center mb-2">
            <FaPhoneAlt className="mr-2" />
            <span> +56 2 2221 2116</span>
          </div>
          <div className="flex items-center mb-2">
            <FaEnvelope className="mr-2" />
            <span> ventas@amf.cl</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
