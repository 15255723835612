import "tailwindcss/tailwind.css";
import PieChartsSection from "./ReportePie";
import React, { useEffect, useState } from "react";
import {
  regionesComunas,
  tarjetasRegionesReportes,
  tarjetasComunaReporte,
} from "./ApiReporte";
import moment from "moment";
import Envios from "./EnviosGrafico";
import Loader from "../../Components/Cargando"; 


const ComponenteReporte = ({ reportData, fechainicio, fechafin }) => {
  const [regiones, setRegiones] = useState([]);
  const [regionesUnicas, setRegionesUnicas] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedComuna, setSelectedComuna] = useState("");
  const [showEnvios, setShowEnvios] = useState(false); 
  const [infoEnvios, setInfoEnvios] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); 
        const data = await regionesComunas();
        const regionNames = data.ok[0].map((region) => region.Region);
        const uniqueRegiones = [...new Set(regionNames)];
        setRegiones(data.ok[0]);
        setRegionesUnicas(uniqueRegiones);
      } catch (error) {
        console.error("Error al obtener regiones y comunas:", error);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchData();
  }, []);

  const handleRegionChange = async (e) => {
    const selectedRegion = e.target.value;
    setSelectedRegion(selectedRegion);
    setIsLoading(true); 

    try {
      const comunasDeRegion = regiones
        .filter((region) => region.Region === selectedRegion)
        .map((region) => region.Comuna);
      setComunas(comunasDeRegion);

      if (selectedRegion) {
        const data = await tarjetasRegionesReportes(
          fechainicio,
          fechafin,
          selectedRegion
        );
        setInfoEnvios(data.ok[0]);
        setSelectedArea(selectedRegion);
        setShowEnvios(true);
      }
    } catch (error) {
      console.error("Error al obtener datos de la región:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  const handleComunaChange = async (e) => {
    const selectedComuna = e.target.value;
    setSelectedComuna(selectedComuna);
    setIsLoading(true); 

    try {
      if (selectedRegion && selectedComuna) {
        const data = await tarjetasComunaReporte(
          fechainicio,
          fechafin,
          selectedComuna
        );
        setInfoEnvios(data.ok[0]);
        setSelectedArea(selectedComuna);
        setShowEnvios(true);
      }
    } catch (error) {
      console.error("Error al obtener datos de la comuna:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  const porcentaje =
    (reportData[0]["Cantidad Devuelta"] /
      reportData[0]["Cantidad Normalizada"]) *
    100;
  const Distribucion =
    reportData[0]["Cantidad En Distribucion"] +
    reportData[0]["Cantidad Problemas Entrega"];
  const EntregaPlazo =
    (reportData[0]["Cantidad Entregada"] /
      reportData[0]["Cantidad Fabricada"]) *
    100;

  return (
    <div>
      {isLoading && <Loader />}
    
<div className="flex p-4 flex-row">
  <div className="flex flex-col w-1/2">
    <div className="">
      {/* Contenido de la columna izquierda */}
      <div className="rounded-lg bg-white p-4 border-r border-gray-300">
        <div className="text-lg font-bold text-gray-500 mb-4">
        Rango Desde: {fechainicio.toLocaleDateString()} Hasta {fechafin.toLocaleDateString()}        </div>
        {/* Contenido de las tres filas */}
        <div className="flex flex-col gap-2">
          {/* Fila 1 */}
          <div className="border-b p-2 bg-gray-100">
            {/* Texto azul grande para mostrar el porcentaje */}
            <div className="text-blue-500 font-bold text-2xl">
              {EntregaPlazo.toFixed(2)}%
            </div>
            {/* Texto gris más pequeño para la descripción */}
            <div className="text-gray-500 text-l">
              De las tarjetas fabricadas fueron entregadas en el periodo.
            </div>
          </div>
          {/* Fila 2 */}
          <div className="border-b p-2 bg-gray-100">
            {/* Texto azul grande para mostrar el porcentaje */}
            <div className="text-blue-500 font-bold text-2xl">
              {porcentaje.toFixed(2)}%
            </div>
            {/* Texto gris más pequeño para la descripción */}
            <div className="text-gray-500 text-l">
              De las tarjetas fabricadas fueron devueltas para planta.
            </div>
          </div>
          {/* Fila 3 */}
          <div className="border-b p-2 bg-gray-100">
            {/* Texto azul grande para mostrar el porcentaje */}
            <div className="text-blue-500 font-bold text-2xl">
              {Distribucion}
            </div>
            {/* Texto gris más pequeño para la descripción */}
            <div className="text-gray-500 text-l">
              Tarjetas en distribución.
            </div>
          </div>
        </div>
    <div>
      {/* Contenido de la columna derecha para el pie de gráfico */}
        <PieChartsSection datapie1={reportData[0]} />
      </div>
  </div>
  </div>
  </div>

  <div className="flex rounded-lg bg-white p-8 border-r border-gray-300 w-1/2">
  <div className="flex flex-col w-full">
    <div className="flex gap-10 mb-8">
      <select
        className="px-2 py-2 block w-1/2 text-gray-500 bg-white border border-blue-500 rounded-lg text-sm"
        onChange={handleRegionChange}
        value={selectedRegion}
      >
        <option value="">Regiones</option>
        {regionesUnicas.map((region, index) => (
          <option key={index} value={region}>
            {region}
          </option>
        ))}
      </select>

      <select
        className="px-2 py-2 block w-1/2 text-gray-500 bg-white border border-blue-500 rounded-lg text-sm"
        onChange={handleComunaChange}
        value={selectedComuna}
        disabled={!selectedRegion}
      >
        <option value="">Comunas</option>
        {comunas.map((comuna, index) => (
          <option key={index} value={comuna}>
            {comuna}
          </option>
        ))}
      </select>
    </div>

    {showEnvios && (
      <div className="flex justify-center items-center w-full">
        <div className="py-10">
          <Envios data={infoEnvios} zona={selectedArea} />
        </div>
      </div>
    )}
  </div>
</div>


  </div>
  </div>
  );
};

export default ComponenteReporte;
