

import React,{ useEffect } from 'react';
import Navbar from "../../Layouts/Navbar/Navbar";
import Footer from "../../Layouts/Footer";
import Error from "../../Assets/images/Error.png";
import { useLocation } from "react-router-dom";

const RutaNoEncontrada= ({ title }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return (
    <div>
    <Navbar />

    <div className="px-8 py-4">
    <div className=" flex justify-center items-center  p-10 rounded-lg shadow-xl bg-white p-8 border-r border-gray-300">
      <div className="text-lg font-bold text-gray-500 mb-4">
        <h2 className="text-lg font-bold mb-4 text-center">¡Lo siento! La ruta consultada no existe. </h2>
        <img src={Error} alt="Error"/>
    
      </div>
    </div>
    </div>

    <Footer /> 
    </div>
  );
};

export default RutaNoEncontrada;
