import { useState } from 'react';
import * as xlsx from 'xlsx';
const useDownloadExcel = (data, fields) => {
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const handleDownloadExcel = async (fileName) => {
    setIsLoadingDownload(true);

    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    xlsx.writeFile(workbook, `${fileName}.xlsx`);

    setIsLoadingDownload(false);
  };

  return { downloadExcel: handleDownloadExcel, isLoadingDownload };
};

export default useDownloadExcel;
