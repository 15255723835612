export const apiStock = async () => {
  try {
    const token = localStorage.getItem("JWT");
    const codemisor = localStorage.getItem("CodEmisor");

    const response = await fetch(`http://200.72.35.93:8080/api/stock?codemisor=${codemisor}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "CodEmisor": codemisor
      },
    });

    if (response.status === 403) {
      window.location.href = "/login";
    }

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Error en la solicitud');
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
