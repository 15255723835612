import React, { useState , useEffect} from "react";
import "tailwindcss/tailwind.css";
import { useLocation } from "react-router-dom";
import Navbar from "../../Layouts/Navbar/Navbar";
import Footer from "../../Layouts/Footer";
import ApiSeguimiento from "./ApiSeguimiento";
import ApiIdTarjeta from "./ApiIdTarjeta";
import BuscadorSeguimiento from "./BuscadorSeguimiento";
import BuscadorIdTarjeta from "./BuscadorIdTarjeta";
import TrackingSeguimiento from "./TrackingSeguimiento";
import Loader from "../../Components/Cargando";
import FondoSeguimiento from "../../Assets/images/FondoSeguimiento.png";

const Seguimiento = ({ title }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = title; 
  }, [location, title]);
  


  const [mostrarImagen, setMostrarImagen] = useState(true);  
  const [codigosSeguimientoArray, setCodigosSeguimientoArray] = useState([]);
  const [idTarjetasArray, setIdTarjetasArray] = useState([]);
  const [datos, setDatos] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [activeTab, setActiveTab] = useState("seguimiento");
  const [erroresSeguimiento, setErroresSeguimiento] = useState([]);
  const [erroresID, setErroresID] = useState([]);

  const handleFormSubmit = async (newCode) => {
    setLoading(true); 
    setErroresSeguimiento([]);
    try {   
      const resultados = [];
      if (activeTab === "seguimiento") {
        if (codigosSeguimientoArray.length === 0 && newCode.trim() !== "" && newCode.length > 10) {
            let data;
            data = await ApiSeguimiento(newCode);
            if (data.error) {
              setErroresSeguimiento((prevErrores) => [...prevErrores, newCode]);
            } else {
              resultados.push(data.ok); 
            }
          }
        else {
        for (const codigo of codigosSeguimientoArray) {
        let data;
        data = await ApiSeguimiento(codigo);
        if (data.error) {
          setErroresSeguimiento((prevErrores) => [...prevErrores, codigo]);
        } else {
          resultados.push(data.ok); 
        }
      }
      }
      }
      if (activeTab === "idTarjeta") {
        if (newCode !== "" && idTarjetasArray.length === 0) {
          let data;
          data = await ApiIdTarjeta(newCode);
          if (data.error) {
            setErroresSeguimiento((prevErrores) => [...prevErrores, newCode]);
          } else {
            resultados.push(data.ok); 
          }
        }
        else {
        for (const codigo of idTarjetasArray) {
          let data;
          data = await ApiIdTarjeta(codigo);
          if (data.error) {
            setErroresID((prevErrores) => [...prevErrores, codigo]);
          } else {
            resultados.push(data.ok); 
          }
        }
        }
        }
        if (resultados.length > 0) {
          setMostrarImagen(false); 
        }
      setDatos(resultados); 
    } catch (error) {
      console.error("Error:", error);
    } finally {

      setLoading(false); 
    }
  };


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  

  return (
    <div className="bg-gray-200 flex flex-col  z-0 font-sans">
      <Navbar />
      <div className="flex justify-center p-4">
  <div className="bg-blue-400 rounded-xl p-2">
    <button
      className={`px-4 py-2 rounded-l-lg ${
        activeTab === "seguimiento" ? "bg-blue-900 rounded-xl text-white" : "bg-blue-400 text-white"
      }`}
      onClick={() => handleTabChange("seguimiento")}
    >
      Código de seguimiento
    </button>
    <button
      className={`px-4 py-2 rounded-r-lg ${
        activeTab === "idTarjeta" ? "bg-blue-900 rounded-xl text-white" : "bg-blue-400 text-white"
      }`}
      onClick={() => handleTabChange("idTarjeta")}
    >
      ID de tarjeta
    </button>
  </div>
</div>


<div className="flex-1 z-40">
        {loading  && <Loader />}
        {activeTab === "seguimiento" ? (
          <>
            <BuscadorSeguimiento
              codigosSeguimientoArray={codigosSeguimientoArray}
              setCodigosSeguimientoArray={setCodigosSeguimientoArray}
              handleFormSubmit={handleFormSubmit}
              erroresSeguimiento={erroresSeguimiento}
            />
            {/* Renderizar TrackingSeguimiento */}
            {datos.length > 0 &&
              datos.map((dato, index) => (
                <React.Fragment key={index}>
                  <TrackingSeguimiento trackingSeguimiento={dato} />
                </React.Fragment>
              ))}
          </>
        ) : (
          <>
            <BuscadorIdTarjeta
              idTarjetasArray={idTarjetasArray}
              setIdTarjetasArray={setIdTarjetasArray}
              handleFormSubmit={handleFormSubmit}
              erroresID={erroresID}
            />
            {/* Renderizar TrackingSeguimiento */}
            {datos.length > 0 &&
              datos.map((dato, index) => (
                <React.Fragment key={index}>
                  <TrackingSeguimiento trackingSeguimiento={dato} />
                </React.Fragment>
              ))}
          </>
        )}
            </div>
    {mostrarImagen && (
      <div className="z-30">
        <img className="top-90 h-[550px] w-full px-12" src={FondoSeguimiento} alt="" />
        <div className="">
          <Footer />
        </div>
      </div>
    )}
    {!mostrarImagen && <Footer />}
  </div>
  );
};

export default Seguimiento;
