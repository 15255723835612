import React from "react";
import { FaRegUser } from "react-icons/fa";
import { CiMap } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { CiCreditCard1 } from "react-icons/ci";
import EstadoSeguimiento1 from "../../Assets/images/EstadoSeguimiento1.png";
import EstadoSeguimiento2 from "../../Assets/images/EstadoSeguimiento2.png";
import EstadoSeguimiento3 from "../../Assets/images/EstadoSeguimiento3.png";
import EstadoSeguimiento4 from "../../Assets/images/EstadoSeguimiento4.png";
import EstadoSeguimiento5 from "../../Assets/images/EstadoSeguimiento5.png";
import EstadoSeguimiento6 from "../../Assets/images/EstadoSeguimiento6.png";
import EstadoSeguimiento7 from "../../Assets/images/EstadoSeguimiento7.png";
import EstadoSeguimiento8 from "../../Assets/images/EstadoSeguimiento8.png";
import "./TrackingSeguimiento.css"; 

const TrackingSeguimiento = ({ trackingSeguimiento }) => {
  const capitalizeWords = (str) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());
  };

  const estadoImagenes = {
    1: EstadoSeguimiento1,
    2: EstadoSeguimiento2,
    3: EstadoSeguimiento3,
    4: EstadoSeguimiento4,
    5: EstadoSeguimiento5,
    6: EstadoSeguimiento6,
    7: EstadoSeguimiento7,
    8: EstadoSeguimiento8,
    10: EstadoSeguimiento5,  
    11: EstadoSeguimiento6,
    13: EstadoSeguimiento7,
    12: EstadoSeguimiento8, 
  };

  const trackingSeguimientoCapitalized = trackingSeguimiento.map((item) => {
    for (const key in item) {
      if (Object.hasOwnProperty.call(item, key)) {
        item[key] = capitalizeWords(item[key]);
      }
    }
    return item;
  });

  const primerElemento = trackingSeguimientoCapitalized[0];
return (
<>
<div className="flex items-start justify-center p-3">
      <div className="w-[1400px] rounded-lg bg-white p-3 shadow-lg  shadow-2xl">
      <div className="flex items-center mb-4">

    <h1 className="font-bold text-2xl mr-2 p-2">Información de envío:</h1>
    <span className="text-blue-500 text-2xl">{primerElemento.CodigoSeguimiento}</span>
</div>

<div className="flex mb-2">
<div className="w-2/3 pr-4">
  <div className="bg-white shadow-md rounded-lg p-6 border border-gray-300">
    <div className="flex items-center mb-2">
      <FaRegUser className="mr-2 text-blue-500" />
      <span className="font-bold">{primerElemento.Nombre}</span>
    </div>
    <div className="border-b-2 border-gray-300 mb-4"></div>

    <div className="flex items-center mb-2">
      <CiLocationOn className="mr-2 text-blue-500" />
      <span>Dirección</span>:
      <span className="font-bold pl-1">{primerElemento.Direccion}</span>
    </div>

    <div className="flex items-center mb-2">
      <CiMap className="mr-2 text-blue-500" />
      <span>Comuna</span>:
      <span className="font-bold pl-1">{primerElemento.Comuna}</span>
    </div>

    <div className="flex items-center mb-2">
      <CiCreditCard1 className="mr-2 text-blue-500" />
      <span>Tipo Tarjeta</span>:
      <span className="font-bold pl-1">{primerElemento.TipoTarjeta}</span>
    </div>

    <div className="flex items-center mb-2">
      <CiCreditCard1 className="mr-2 text-blue-500" />
      <span>Id Tarjeta</span>:
      <span className="font-bold pl-1">{primerElemento.IdTarjeta}</span>
    </div>

    <div className="flex items-center mb-2">
      <CiMap className="mr-2 text-blue-500" />
      <span>Correo</span>:
      <span className="font-bold pl-1">{primerElemento.Correo}</span>
    </div>
  </div>
</div>


<div className="w-full flex flex-col p-2">
  {trackingSeguimiento.map((item, index) => (
    <div key={index} className="flex items-center">
      <div className="w-1/6 flex flex-col items-center">
        {item.IdEstadoDetalleSolicitud && estadoImagenes[item.IdEstadoDetalleSolicitud] && (
          <img
            src={estadoImagenes[item.IdEstadoDetalleSolicitud]}
            alt={`Estado ${item.IdEstadoDetalleSolicitud}`}
            className="w-[110px] h-[110px] rounded-full"
          />
        )}
        {index !== trackingSeguimiento.length - 1 && (
          <div className="border-l-2 border-gray-200 h-12"></div>
        )}
      </div>
     <div className="w-5/6 ml-4">
        <div className="rounded-lg pb-10">
          <div className="flex items-center mb-2">
            <span className="font-bold">{item.NombreEstadoSolicitud}</span>
          </div>
          <div className="flex items-center mb-2">
            <span className="">{item.FechaCreacion}</span>
          </div>
          {item.NombreEstadoCorreo ? (
            <div className="flex items-center mb-2">
              <span className="">Estado de correo:&nbsp;</span>
              <span className="">{item.NombreEstadoCorreo}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ))}
</div>






</div>
</div>
</div>

</>
  );
};

export default TrackingSeguimiento;

