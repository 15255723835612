import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import RadianTooltip from "./RadianTooltip";

const PieChartsSection = ({ datapie1 }) => {
  const filteredData = [
    { name: 'Cantidad Entregada', value: datapie1['Cantidad Entregada'] },
    { name: 'Cantidad Fabricada', value: datapie1['Cantidad Fabricada'] },
    { name: 'Cantidad Normalizada', value: datapie1['Cantidad Normalizada'] },
    { name: 'Cantidad Ingresada', value: datapie1['Cantidad Ingresada'] },
    { name: 'Cantidad Devuelta', value: datapie1['Cantidad Devuelta'] },
  ];//.filter(item => item.value >= 100);

  const colors = [
    '#1976d2', 
    '#64b5f6',
    '#42a5f5', 
    '#0097a7', 
    '#2196f3', 
    '#1e88e5', 
  ];

  return (
    <div className="flex flex-col  flex-row items-top justify-center items-center ">
      <div className="flex flex-row  justify-center items-center">
        <PieChart width={800} height={400}>
          <Pie
            data={filteredData}
            dataKey="value"
            innerRadius={40}
            outerRadius={100}
            labelLine={false}
            label={RadianTooltip}
          >
            {filteredData.map((entry, index) => (
              <Cell 
                fill={colors[index % colors.length]}
                key={`cell-${index}`}
                style={{
                  filter: `drop-shadow(0px 0px 5px ${colors[index % colors.length]})`
                }}
                stroke="0"
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export default PieChartsSection;
