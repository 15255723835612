import React, { useState } from "react";

const SeguimientoForm = ({ idTarjetasArray, setIdTarjetasArray, handleFormSubmit, erroresID }) => {
  const [idtarjeta, setidtarjeta] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 40) {
      setidtarjeta(inputValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      if (idtarjeta.trim() !== "" && idtarjeta.length > 6) {
        setIdTarjetasArray([...idTarjetasArray, idtarjeta.trim()]);
        setidtarjeta("");
      }
    }
  };

  const handleDeleteItem = (index) => {
    const updatedList = [...idTarjetasArray];
    updatedList.splice(index, 1);
    setIdTarjetasArray(updatedList);
  };

  const handleFormClick = (index) => {
    handleFormSubmit(idtarjeta.trim());
  };
  return (
    <div>
      <div className="flex w-[1200px] items-start mx-auto justify-center p-3 relative z-10">
        <div className="w-full rounded-lg bg-white p-3 w-3/4 shadow-lg z-2 shadow-2xl">
          <div className="flex flex-col">
            <div className="flex items-center">
        <div className="w-2/5"> {/* Columna para el texto */}
        <div className="text-lg font-semibold text-black m-2 mb-2 row-span-1">
        Realiza tu seguimiento en línea.
      </div>
      <div className="text-sm text-black m-2 mb-2 row-span-1">
        Ingresa el código de la tarjeta para conocer más información.
      </div>        
      </div>
      <div className="w-2/5 relative"> {/* Columna para el campo de búsqueda */}
        <input
          type="text"
          className="w-full h-12 bg-white pl-2 text-base font-semibold outline-0 border-2 border-blue-500 rounded-lg focus:outline-none focus:border-blue-700"
          placeholder="Ingresa el identificador de la tarjeta."
          maxLength={40}
          value={idtarjeta}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={(e) => e.target.placeholder = ""}
          onBlur={(e) => e.target.placeholder = "Ingresa tu número de pedido"}
          id=""
        />
      </div>

        <div className="w-1/5"> {/* Columna para el botón */}
          <input
            type="button"
            value="Buscar"
            className="w-44 bg-blue-900 p-3 rounded-lg text-white font-semibold hover:bg-blue-500 transition-colors cursor-pointer ml-2 "
            onClick={handleFormClick}
          />
        </div>
      </div>
      <div className="flex flex-wrap mt-3">
      {idTarjetasArray.map((item, index) => (
        <div
          key={index}
          className={`rounded-lg p-2 m-1 flex items-center ${
            erroresID && erroresID.includes(item)
              ? 'bg-red-200' // Estilo para códigos de seguimiento con errores si erroresSeguimiento está definido
              : 'bg-blue-200' // Estilo predeterminado para otros códigos
          }`}
        >
          <span className="mr-2">{item}</span>
          <button
            className="font-bold text-blue-800"
            onClick={() => handleDeleteItem(index)}
          >
            X
          </button>
        </div>
      ))}
    </div>
      
    </div>

  </div>  
</div>
</div>
  );
};

export default SeguimientoForm;