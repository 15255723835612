import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { MdLogout } from "react-icons/md";
import AmfLogo from "../../Assets/images/amf_seg_logo.svg";
import { FaBell } from "react-icons/fa";
import Mach from "../../Assets/images/Mach.png";
import AmfIcono from "../../Assets/images/AmfLogo.jpg";
import Tapp from "../../Assets/images/Tapp.png";
import Tenpo from "../../Assets/images/Tenpo.png";
import Falabella from "../../Assets/images/Falabella.png";
import Destacame from "../../Assets/images/Destacame.png";
import Default from "../../Assets/images/Default.png";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
  };
  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();
  let imagenSrc;

  switch (localStorage.getItem("CodEmisor")) {
    case "FAL":
      imagenSrc = Falabella;
      break;
    case "TAP":
      imagenSrc = Tapp;
      break;
    case "016":
      imagenSrc = Mach;
      break;
    case "TEN":
      imagenSrc = Tenpo;
      break;
    case "DES":
      imagenSrc = Destacame;
    case "AMF":
      imagenSrc = AmfIcono;      
      break;      
    default:
      imagenSrc = Default;
  }

  return (
    <div>
      <header className="bg-white hidden h-20 md:flex justify-between items-center">
        <div className="flex-initial items-center justify-center px-4 lg:px-6 xl:px-8" style={{ width: "250px" }}>
        <img className="w-full h-full object-fit-contain" src={AmfLogo} alt="" /> 
        </div>
        <nav className="text-base lg:text-lg flex-grow flex justify-center items-center">
          <ul className="flex items-center space-x-8">
            <li className={`p-2 xl:p-2 ${location.pathname === '/seguimiento' ? 'border-b-2 border-blue-900' : ''}`}>
              <Link to="/seguimiento">
                <span>Seguimiento</span>
              </Link>
            </li>
            <li className={`p-2 xl:p-2 ${location.pathname === '/reportes' ? 'border-b-2 border-blue-900' : ''}`}>
              <Link to="/reportes">
                <span>Reportes</span>
              </Link>
            </li>
            <li className={`p-2 xl:p-2 ${location.pathname === '/tarjetas' ? 'border-b-2 border-blue-900' : ''}`}>
              <Link to="/tarjetas">
                <span>Sucursal</span>
              </Link>
            </li>
            <li className={`p-2 xl:p-2 ${location.pathname === '/stock' ? 'border-b-2 border-blue-900' : ''}`}>
              <Link to="/stock">
                <span>Stock</span>
              </Link>
            </li>
          </ul>
        </nav>


<div className="relative">
  <div onClick={handleToggleMenu} className="cursor-pointer flex flex-row">
    <div className="w-8 h-8">
      <img className="h-full w-full rounded-full object-cover" src={imagenSrc} alt="avatar" />
    </div>
    
    <div className="p-2" >
      Mi Cuenta
    </div>
  </div>

  {isOpen && (
    <div className="absolute mt-2 py-2 bg-white border rounded shadow-xl right-0 w-[200px]">   
      <Link to="/cambioclave" className="transition-colors duration-200 block px-4 py-2 text-normal text-gray-900 rounded hover:bg-blue-500 hover:text-white">    
        Cambiar Contraseña
      </Link>
    </div>
  )}
</div>


        <div className="p-8">
         <FaBell  className="text-blue-500 text-2xl"/>
        </div>
<div className="flex items-center pr-4">
  <Link to="/" onClick={handleLogout} className="flex items-center">
    <MdLogout className="text-blue-500 text-2xl" />
    <span className="p-1">Cerrar Sesión</span>
  </Link>
</div>

      </header>
    </div>
  );
};

export default Navbar;
