import React, { useState, useEffect } from 'react';
import { ComposedChart, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Area, ResponsiveContainer } from 'recharts';
import Error from "../../Assets/images/Error.png";

const EnviosRegion = ({ data, zona }) => {
  if (!data || data.length === 0 || data.every(item => item.Cantidad_Devuelta === 0 && item.Cantidad_Entregada === 0)) {
    return (
      <div className="px-8 py-4">
        <div className="">
          <div className="text-lg font-bold text-gray-500 mb-4">
            <h2 className="text-lg font-bold mb-4 text-center">¡Lo siento! No hay información disponible en este momento. </h2>
            <img src={Error} alt="Error" />
          </div>
        </div>
      </div>
    );
  } else if (data.length === 1) {
    const item = data[0];
    return (
      <div className="">
        <div className="">
          <div className="text-lg font-bold text-gray-500 mb-4">
            <h2 className="text-lg font-bold mb-4 text-center">Envíos en {zona} </h2>
            <ComposedChart
              layout="horizontal"
              width={600}
              height={400}
              data={[{ x: '', ...item }]}
              margin={{
                top: 20,
                right: 10,
                bottom: 20,
                left: 60,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="x" type="category" scale="band" />
              <YAxis type="number" />
              <Tooltip />
              <Legend />
              <Bar dataKey="Cantidad_Devuelta" fill="#009FE3" name="Tarjetas Devueltas" />
              <Bar dataKey="Cantidad_Entregada" fill="#47BE7E" name="Tarjetas Entregadas" />
            </ComposedChart>
          </div>
        </div>
      </div>
    );
  } else {
    const transformedData = data.map(item => ({
      x: item.Comuna,
      y: {
        Cantidad_Devuelta: item.Cantidad_Devuelta,
        Cantidad_Entregada: item.Cantidad_Entregada,
      },
    }));


    return (
        <div className="text-lg font-bold text-gray-500 mb-4">
          <h2 className="text-lg font-bold mb-4 text-center">Envíos en {zona}</h2>
          <div className="flex justify-between">
    <AreaChart
      width={600}
      height={500} 
      data={transformedData}
      margin={{
        bottom: 80,
      }}
    >
      <CartesianGrid strokeDasharray="2 2" />
      <XAxis 
        dataKey="x" 
        angle={90} 
        tick={{ fontSize: 12 }} 
        textAnchor="start" 
      />
      <YAxis/>
      <Tooltip />
      <Area type="monotone" dataKey="y.Cantidad_Devuelta" stackId="1" fill="#009FE3" stroke="#8884d8" name="Tarjetas Devueltas" />
      <Area type="monotone" dataKey="y.Cantidad_Entregada" stackId="1" fill="#47BE7E" stroke="#82ca9d" name="Tarjetas Entregadas" />
    </AreaChart>
</div>

          </div>
    );
  }
};

export default EnviosRegion;
