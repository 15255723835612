const ApiCambioClave = async (clavenueva, clavevieja) => {
    try {
      const token = localStorage.getItem("JWT");
      const response = await fetch("http://200.72.35.93:8080/api/cambioclave", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, 
          codemisor : localStorage.getItem("CodEmisor"),
        },
        body: JSON.stringify({
          clavenueva: clavenueva,
          clavevieja : clavevieja,
          codemisor : localStorage.getItem("CodEmisor"),
          usuario : localStorage.getItem("Usuario"),
        }),
      });
      if (response.status === 403) {
        window.location.href = "/login";
      }
      if (response.ok) {
        const data = await response.json();
        
        return data;
      } else {
        throw new Error('Error en la solicitud');
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
  
  export default ApiCambioClave;
  