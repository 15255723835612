import React, { useState } from "react";
import { ImageMap } from "@qiuz/react-image-map";
import Mapa from "./Chile.png";

const InteractiveMap = ({ dataregiones, handleRegionClick }) => {
  const mapArea = [
    {
      width: "4.1890243902438975%",
      height: "18.766917293233085%",
      left: "3.5887171848817734%",
      top: "30.568875017733017%",
      onClick: () => onMapClick(1),
      onMouseOver: () => onAreaMouseOver(1),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "5.0304878048780495%",
      height: "21.804511278195488%",
      left: "7.573679016857622%",
      top: "27.06766917293233%",
      onClick: () => onMapClick(15),
      onMouseOver: () => onAreaMouseOver(15),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "10.518292682926829%",
      height: "32.8345864661654%",
      left: "12.604166821735655%",
      top: "14.285714285714235%",
      onClick: () => onMapClick(14),
      onMouseOver: () => onAreaMouseOver(14),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "7.774390243902439%",
      height: "31.57894736842105%",
      left: "23.122459504662498%",
      top: "24.812030075187938%",
      onClick: () => onMapClick(13),
      onMouseOver: () => onAreaMouseOver(13),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "7.164634146341463%",
      height: "21.052631578947363%",
      left: "30.744410724174685%",
      top: "39.849624060150354%",
      onClick: () => onMapClick(12),
      onMouseOver: () => onAreaMouseOver(12),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "2.1341463414634148%",
      height: "21.052631578947363%",
      left: "40.34806926076004%",
      top: "39.84962406015036%",
      onClick: () => onMapClick(3),
      onMouseOver: () => onAreaMouseOver(3),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "2.1341463414634148%",
      height: "21.052631578947363%",
      left: "42.177337553442996%",
      top: "41.3533834586466%",
      onClick: () => onMapClick(10),
      onMouseOver: () => onAreaMouseOver(10),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "3.6585365853658534%",
      height: "22.556390977443602%",
      left: "44.00660584612592%",
      top: "45.86466165413532%",
      onClick: () => onMapClick(9),
      onMouseOver: () => onAreaMouseOver(9),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "2.591463414634146%",
      height: "22.556390977443602%",
      left: "37.75660584612593%",
      top: "38.34586466165412%",
      onClick: () => onMapClick(11),
      onMouseOver: () => onAreaMouseOver(11),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "1.9817073170731707%",
      height: "25.563909774436077%",
      left: "47.51270340710153%",
      top: "45.11278195488721%",
      onClick: () => onMapClick(0),
      onMouseOver: () => onAreaMouseOver(0),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "1.9817073170731707%",
      height: "30.82706766917291%",
      left: "49.49441072417472%",
      top: "44.36090225563909%",
      onClick: () => onMapClick(8),
      onMouseOver: () => onAreaMouseOver(8),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "3.6585365853658534%",
      height: "31.578947368421044%",
      left: "51.32367901685762%",
      top: "43.60902255639097%",
      onClick: () => onMapClick(7),
      onMouseOver: () => onAreaMouseOver(7),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "2.8963414634146343%",
      height: "25.563909774436087%",
      left: "54.82977657783322%",
      top: "49.62406015037593%",
      onClick: () => onMapClick(2),
      onMouseOver: () => onAreaMouseOver(2),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "7.926829268292683%",
      height: "27.819548872180448%",
      left: "57.57367901685758%",
      top: "53.383458646616546%",
      onClick: () => onMapClick(6),
      onMouseOver: () => onAreaMouseOver(6),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "13.567073170731708%",
      height: "43.609022556390975%",
      left: "65.50050828515029%",
      top: "48.12030075187963%",
      onClick: () => onMapClick(5),
      onMouseOver: () => onAreaMouseOver(5),
      onMouseOut: () => onAreaMouseOut(),
    },
    {
      width: "17.073170731707318%",
      height: "71.42857142857143%",
      left: "78.91514243149182%",
      top: "17.29323308270672%",
      onClick: () => onMapClick(4),
      onMouseOver: () => onAreaMouseOver(4),
      onMouseOut: () => onAreaMouseOut(),
    },
  ];

  const [hoveredRegion, setHoveredRegion] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const onMapClick = (index) => {
    if (dataregiones[index]) {
      const region = dataregiones[index].x;
      handleRegionClick(region);
    } else {
      console.error("No se encontraron datos para la región seleccionada");
    }
  };

  const onAreaMouseOver = (index) => {
    if (dataregiones[index]) {
      setHoveredRegion(dataregiones[index]);
    } else {
      console.error("No se encontraron datos para la región seleccionada");
    }
  };
  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };
  const onAreaMouseOut = () => {
    setHoveredRegion(null);
  };

  return (
    <div
      className="flex flex-wrap justify-start pb-20"
      onMouseMove={handleMouseMove}
    >
      <div style={{ cursor: "pointer" }}>
        <ImageMap
          src={Mapa}
          map={mapArea}
          onMapClick={(area) => area.onClick()}
        />
      </div>
      {hoveredRegion && mousePosition.x && mousePosition.y && (
        <div
          className="absolute bg-white p-4 rounded-lg border-blue-500 border"
          style={{
            top: `${mousePosition.y + 100}px`,
            left: `${mousePosition.x + 15}px`,
          }}
        >
          <div className="flex items-center justify-center p-2 text-l font-bold">
            <span className="inline-block align-middle">{hoveredRegion.x}</span>
          </div>
          <div className="border-b border-blue-300 w-full my-2"></div>
          <div className="flex items-center justify-center p-2 text-l">
            <p className="text-xl font-bold mb-1 mr-2 inline-block">
              {hoveredRegion.Cantidad_Planta_Destino +
                hoveredRegion.Cantidad_Sucursal}
            </p>
            <p className="text-l inline-block align-middle">Unidades</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default InteractiveMap;
