import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "tailwindcss/tailwind.css";
import { useLocation } from "react-router-dom";
import Navbar from "../../Layouts/Navbar/Navbar";
import Footer from "../../Layouts/Footer";
import "react-calendar/dist/Calendar.css";
import { reporteMes } from "./ApiReporte";
import toast from "react-hot-toast";
import ComponenteReporte from "./ComponenteReporte";
import Loader from "../../Components/Cargando";
import { FaRegCalendar } from "react-icons/fa";

const Reportes = ({ title }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = title; 
  }, [location, title]);

  const [showModal, setShowModal] = useState(false);
  const [primeraCarga, setPrimeraCarga] = useState(true);
  const [loading, setLoading] = useState(false);
  const minDate = new Date();
  const [reportData, setReportData] = useState(null);
  const [datosCargados, setDatosCargados] = useState(false);
  const [fechaInicioDefault, setFechaInicioDefault] = useState(null);
  const [fechaFinDefault, setFechaFinDefault] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [selectedDateType, setSelectedDateType] = useState(null);
  const [fechaVisibleDesde, setFechaVisibleDesde] = useState("Desde");
  const [fechaVisibleHasta, setFechaVisibleHasta] = useState("Hasta");
  const maxDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 10);

  const changeDateInicio = (e) => {
    if (primeraCarga){
      setPrimeraCarga(false);
    }
    setFechaInicio(e);
    const formattedDate = `${e.getDate().toString().padStart(2, "0")}/${(
      e.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${e.getFullYear()}`;
    setFechaVisibleDesde(formattedDate);
  };

  const changeDateFin = (e) => {
    if (primeraCarga){
      setPrimeraCarga(false);
    }
    setFechaFin(e);
    const formattedDate = `${e.getDate().toString().padStart(2, "0")}/${(
      e.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${e.getFullYear()}`;
    setFechaVisibleHasta(formattedDate);
  };

  const openCalendar = (type) => {
    setShowModal(true);
    setSelectedDateType(type);
  };

  const handleFormSubmit = async () => {
    if(fechaInicio !== fechaInicioDefault && fechaFin !== fechaFinDefault){
    if (fechaInicio && fechaFin) {
      if (fechaInicio > fechaFin) {
        toast.error("Rango de fecha inválido.");
        return;
      }
      if (fechaInicio.toDateString() === fechaFin.toDateString()) {
        fechaFin.setHours(23, 59, 59);
      }
      setLoading(true);
      reporteMes(fechaInicio, fechaFin)
        .then((data) => {
          if (data.error) {
            toast.error(data.error);
          } else {
            setDatosCargados(true);
            setReportData(data.ok[0]);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  };


  useEffect(() => {
    const fechaHoy = new Date();
    const fechaInicioDefault = new Date(fechaHoy);
    fechaInicioDefault.setDate(fechaHoy.getDate() - 30);
    // Actualizamos los estados de fechaInicio y fechaFin con los valores iniciales
    setLoading(true);
    setFechaInicio(fechaInicioDefault);
    setFechaFin(fechaHoy);
    setFechaInicioDefault(fechaInicioDefault);
    setFechaFinDefault(fechaHoy);
    reporteMes(fechaInicioDefault, fechaHoy)
    .then((data) => {
      if (data.error) {
        toast.error(data.error);
      } else {
        setDatosCargados(true);
        setReportData(data.ok[0]);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);


  
  return (
    <div className="bg-gray-200 font-sans">
      <Navbar />
      <div className="flex items-start justify-center">
  <div className="w-full p-3 flex justify-center items-center">
    <div className="flex items-center m-2 mb-2 p-2">
      <FaRegCalendar className="text-blue-500 mr-2" />
      <span> Selecciona otro período  </span>
    </div>
    <button
  className="border border-blue-600 rounded-full text-gray-600 h-10 px-16 bg-white focus:outline-none appearance-none mr-8 items-left"
  onClick={() => openCalendar("inicio")}
>
  {fechaVisibleDesde}
</button>
<button
  className="border border-blue-600 rounded-full text-gray-600 h-10 px-16 bg-white focus:outline-none appearance-none ml-8 text-left"
  onClick={() => openCalendar("fin")}
>
  {fechaVisibleHasta}
</button>
  </div>
</div>


      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Seleccionar Fecha</h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {selectedDateType === "inicio" ? (
                    <Calendar
                      value={fechaInicio}
                      onChange={changeDateInicio}
                      defaultView="year"
                      maxDetail="month"
                      maxDate={maxDate}
                      minDate={minDate}
                    />
                  ) : (
                    <Calendar
                      value={fechaFin}
                      onChange={changeDateFin}
                      defaultView="year"
                      maxDetail="month"
                      maxDate={maxDate}
                      minDate={minDate}
                    />
                  )}
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Salir
                  </button>

                  <button
                    className="bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      handleFormSubmit();
                      setShowModal(false);
                    }}
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}


{loading ? (
  <Loader /> 
) : datosCargados && fechaInicio && fechaFin ? (
  <ComponenteReporte
    reportData={reportData}
    fechainicio={fechaInicio}
    fechafin={fechaFin}
  />
) : null}



      <Footer />
    </div>
  );
};

export default Reportes;
