import React, { useState } from "react";

const IdTarjetaForm = ({ codigosSeguimientoArray, setCodigosSeguimientoArray, handleFormSubmit, erroresSeguimiento 
}) => {
  const [codigoseguimiento, setcodigoseguimiento] = useState("");
  const handleChange = (e) => {
    const inputValue = e.target.value;

    const numericValue = inputValue.replace(/[^0-9 ]/g, "");

    if (numericValue.length <= 12) {
      setcodigoseguimiento(numericValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (codigoseguimiento.trim() !== "" && codigoseguimiento.length > 10) {
        setCodigosSeguimientoArray([
          ...codigosSeguimientoArray,
          codigoseguimiento.trim(),
        ]);
        setcodigoseguimiento("");
      }
    }
  };

  const handleFormClick = (index) => {
    handleFormSubmit(  codigoseguimiento.trim());
  };


  const handleDeleteItem = (index) => {
    const updatedList = [...codigosSeguimientoArray];
    updatedList.splice(index, 1);
    setCodigosSeguimientoArray(updatedList);
  };

  return (
    <div>
      <div className="flex w-[1200px] items-start mx-auto justify-center p-3 relative z-10">
        <div className="w-full rounded-lg bg-white p-3 w-3/4 shadow-lg z-2 shadow-2xl">
          <div className="flex flex-col">
            <div className="flex items-center">
              <div className="w-2/5">
                {" "}
                {/* Columna para el texto */}
                <div className="text-lg font-semibold text-black m-2 mb-2 row-span-1">
                  Realiza tu seguimiento en línea.
                </div>
                <div className="text-sm text-black m-2 mb-2 row-span-1">
                  Ingresa el código del pedido para conocer más información.
                </div>
              </div>
              <div className="w-2/5 relative">
                {" "}
                {/* Columna para el campo de búsqueda */}
                <input
                  type="text"
                  className="w-full h-12 bg-white pl-2 text-base font-semibold outline-0 border-2 border-blue-500 rounded-lg focus:outline-none focus:border-blue-700"
                  placeholder="Ingresa tu número de pedido."
                  maxLength={12}
                  value={codigoseguimiento}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) =>
                    (e.target.placeholder = "Ingresa tu número de pedido")
                  }
                  id=""
                />
              </div>

              <div className="w-1/5">
                {" "}
                {/* Columna para el botón */}
                <input
                  type="button"
                  value="Buscar"
                  className="w-44 bg-blue-900 p-3 rounded-lg text-white font-semibold hover:bg-blue-500 transition-colors cursor-pointer ml-2 "
                  onClick={handleFormClick}
                />
              </div>
            </div>
            <div className="flex flex-wrap mt-3">
      {codigosSeguimientoArray.map((item, index) => (
        <div
          key={index}
          className={`rounded-lg p-2 m-1 flex items-center ${
            erroresSeguimiento && erroresSeguimiento.includes(item)
              ? 'bg-red-500' 
              : 'bg-blue-200' 
          }`}
        >
          <span className="mr-2">{item}</span>
          <button
            className="font-bold text-blue-800"
            onClick={() => handleDeleteItem(index)}
          >
            X
          </button>
        </div>
        
      ))}

    </div>
    {erroresSeguimiento && erroresSeguimiento.length > 0 && (
      <div className="text-red-600 mt-2 font-semibold">
        No se pudieron cargar algunos códigos.
      </div>
    )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdTarjetaForm;
