import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import Loader from "../Components/Cargando";

function Protected({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        
        const token = localStorage.getItem('JWT');
        const response = await axios.get('http://200.72.35.93:8080/api/check-auth', {
          headers: {
            Authorization: `Bearer ${token}`
                    },
        });
        setIsAuthenticated(response.data.isAuthenticated);
        setIsLoading(false);
      } catch (error) {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };
    
    checkAuth();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default Protected;
