export const reporteMes = async (fechainicio,fechafin) => {
  try {
    const token = localStorage.getItem("JWT"); 
    const response = await fetch("http://200.72.35.93:8080/api/reportecantidades", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
        codemisor : localStorage.getItem("CodEmisor"),
      },
      body: JSON.stringify({
        fechainicio: fechainicio,
        fechafin: fechafin,
        codemisor : localStorage.getItem("CodEmisor"),
      }),
    });
    if (response.status === 403) {
      window.location.href = "/login";
    }
    if (response.ok) {
      const data = await response.json();
      return data;
    }      
    else {
      throw new Error('Error en la solicitud');
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const regionesComunas = async () => {
  try {
    const token = localStorage.getItem("JWT"); 
    const response = await fetch("http://200.72.35.93:8080/api/regionescomunas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
        codemisor : localStorage.getItem("CodEmisor"),
      },
      body: JSON.stringify({
        codemisor : localStorage.getItem("CodEmisor"),
      }),
    });
    if (response.status === 403) {
      window.location.href = "/login";
    }
    if (response.ok) {
      const data = await response.json();
      return data;
    }      
    else {
      throw new Error('Error en la solicitud');
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const tarjetasRegionesReportes = async (fechainicio,fechafin,region) => {
  try {
    const token = localStorage.getItem("JWT"); 
    const response = await fetch("http://200.72.35.93:8080/api/tarjetasregionesreportes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
        codemisor : localStorage.getItem("CodEmisor"),
      },
      body: JSON.stringify({
        fechainicio: fechainicio,
        fechafin: fechafin,
        region: region,
        codemisor : localStorage.getItem("CodEmisor"),
      }),
    });
    if (response.status === 403) {
      window.location.href = "/login";
    }
    if (response.ok) {
      const data = await response.json();
      return data;
    }      
    else {
      throw new Error('Error en la solicitud');
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const tarjetasComunaReporte = async (fechainicio,fechafin,comuna) => {
  try {
    const token = localStorage.getItem("JWT"); 
    const response = await fetch("http://200.72.35.93:8080/api/tarjetascomunareporte", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
        codemisor : localStorage.getItem("CodEmisor"),
      },
      body: JSON.stringify({
        fechainicio: fechainicio,
        fechafin: fechafin,
        comuna: comuna,
        codemisor : localStorage.getItem("CodEmisor"),
      }),
    });
    if (response.status === 403) {
      window.location.href = "/login";
    }
    if (response.ok) {
      const data = await response.json();
      return data;
    }      
    else {
      throw new Error('Error en la solicitud');
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
