import React, { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import { useLocation } from "react-router-dom";
import Navbar from "../../Layouts/Navbar/Navbar";
import Footer from "../../Layouts/Footer";
import imgStock from "../../Assets/images/Stock.png";
import img409767 from "../../Assets/images/stock_Falabella/img409767.png";
import img434503 from "../../Assets/images/stock_Falabella/img434503.png";
import img512269 from "../../Assets/images/stock_Falabella/img512269.png";
import img522468 from "../../Assets/images/stock_Falabella/img522468.png";
import img548742 from "../../Assets/images/stock_Falabella/img548742.png";
import img533187 from "../../Assets/images/stock_Falabella/img533187.png";
import contenedorDebitoMach from "../../Assets/images/stock_Mach/Contenedor_Debito.png";
import contenedorPrepagoMach from "../../Assets/images/stock_Mach/Contenedor_Prepago.png";
import tarjetaDebitoMach from "../../Assets/images/stock_Mach/Frontal_Debito.png";
import tarjetaPrepagoMach from "../../Assets/images/stock_Mach/Frontal_Prepago.png";
import stickerAntiguoMach from "../../Assets/images/stock_Mach/Sticker_Antiguo.png";
import stickerNuevoMach from "../../Assets/images/stock_Mach/Sticker_Nuevo.png";
import sobreMach from "../../Assets/images/stock_Mach/Sobre.png";
import cmrGris from "../../Assets/images/stock_Falabella/cmrGris.png";
import cmrGrisOscuro from "../../Assets/images/stock_Falabella/cmrGrisOscuro.png";
import cmrNegro from "../../Assets/images/stock_Falabella/cmrNegro.png";
import cmrVerde from "../../Assets/images/stock_Falabella/cmrVerde.png";
import tarjetaTappAzul from "../../Assets/images/stock_Tapp/tarjetaTappAzul.png";
import stickerTapp from "../../Assets/images/stock_Tapp/StickerTapp.png";
import sobreTapp from "../../Assets/images/stock_Tapp/TappSobre.png";
import tripticoTapp from "../../Assets/images/stock_Tapp/TappTriptico.png";
import sobreDestacame from "../../Assets/images/stock_Destacame/SobreDestacame.png";
import tripticoDestacame from "../../Assets/images/stock_Destacame/tripticoDestacame.png";
import tarjetaDestacame from "../../Assets/images/stock_Destacame/TarjetaDestacame.png";




import { apiStock } from "./ApiStock.jsx";
import Loader from "../../Components/Cargando";
import { FaPlusCircle } from "react-icons/fa";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import "./Stock.css"; 
import  useDownloadExcel  from "./useDownloadExcel";

const Stock = ({ title }) => {
  const VISIBLE_FIELDS = [
    {
      field: "Nombre",
      headerName: "Nombre",
      flex: 4,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Virgen",
      headerName: "Virgen",
      flex: 1,
      type: "number",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Rechazada",
      headerName: "Rechazada",
      flex: 1,
      type: "number",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Fallida",
      headerName: "Fallida",
      flex: 1,
      type: "number",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "Miniatura",
      headerName: "Miniatura",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const nombre = params.row.Nombre;
        return (
          <div>
            <img
              src={getBinImage(nombre)}
              alt="Imagen Stock"
              className="w-12 h-8"
            />
          </div>
        );
      },
    },
    {
      field: "Ver",
      headerName: "Info.",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const idTarjeta = params.id; 
        return (
          <div className="flex items-center justify-center p-2 text-l font-bold">
            <FaPlusCircle className="text-xl mb-1 mr-2 text-blue-500 inline-block" />
            <button
              className="text-blue-500 hover:text-blue-300"
              onClick={() => openModal(idTarjeta)}
            >
              Ver más
            </button>
          </div>
        );
      },
    },
  ];

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const openModal = async (id) => {
    setShowModal(true);
    setSelectedItem(dataStock[id - 1]);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const [dataStock, setDataStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    document.title = title; 
  }, [location, title]);

  const getBinImage = (bin) => {
    switch (bin) {
      case "CUSTODIA BIN 409767 - TARJETA CMR THALES DEBITO VISA CUENTA CORRIENTE - R09":
        return img409767;
      case "CUSTODIA BIN 434503 - TARJETA CMR THALES DEBITO VISA CUENTA VISTA - R09":
        return img434503;
      case "CUSTODIA BIN 512269 - TARJETA CMR IDEMIA CREDITO MASTERCARD PLATINUM - SF30":
        return img512269;
      case "CUSTODIA BIN 522468 - TARJETA CMR IDEMIA CREDITO MASTERCARD BLACK - SF30":
        return img522468;
      case "CUSTODIA BIN 548742 - TARJETA CMR IDEMIA CREDITO MASTERCARD CLASICA - SF30":
        return img548742;
      case "CUSTODIA BIN 533187 - TARJETA CMR THALES DEBITO MASTERCARD CUENTA CORRIENTE - VERTICAL - R09":
        return img533187;
      case "CUSTODIA BIN 533187 - TARJETA CMR THALES DEBITO MASTERCARD CUENTA CORRIENTE - VERTICAL - R12":
        return img533187;
      case "PORTA TARJETAS BANCO FALABELLA GRIS":
        return cmrGris;
      case "PORTA TARJETAS CMR CREDITO PLATINUM - COLOR GRIS OSCURO":
        return cmrGrisOscuro;
      case "PORTA TARJETAS CMR CREDITO NORMAL - COLOR VERDE":
        return cmrVerde;
      case "PORTA TARJETAS CMR CREDITO ELITE - COLOR NEGRO":
        return cmrNegro;
      case "CUSTODIA - TARJETA DE PREPAGO TAPP - MASTERCARD DI":
        return tarjetaTappAzul;
      case "TARJ. CUSTODIA VISA MACH PREESTAMPACION BANCO BCI":
        return tarjetaPrepagoMach;
      case "TARJ. CUSTODIA VISA MACH DEBITO PREESTAMPACION BANCO BCI - JUNIOR":
        return tarjetaDebitoMach;
      case "TARJ. CUSTODIA VISA MACH DEBITO PREESTAMPACION BANCO BCI - ADULTO":
        return tarjetaDebitoMach;             
      case "PANES PARA PREESTAMPACION DE TARJETAS DE PREPAGO MACH":
        return tarjetaPrepagoMach;      
      case "PANES PARA PREESTAMPACION DE TARJETAS MACH DEBITO JUNIOR":
        return tarjetaDebitoMach; 
      case "PANES PARA PREESTAMPACION DE TARJETAS MACH DEBITO ADULTO":
        return tarjetaDebitoMach;                     
      case "CUSTODIA BANCO BCI MACH DEBITO CONTENEDOR 22,2X29,7 CM":
        return contenedorDebitoMach;
      case "CUSTODIA BANCO BCI MACH STICKERS 8X8 CM - NUEVO DISEÑO":
        return stickerNuevoMach;
      case "CUSTODIA BANCO BCI MACH STICKERS 4X4 CM - NUEVO DISEÑO":
        return stickerNuevoMach;
      case "CUSTODIA BANCO BCI MACH STICKERS 8X8 CM":
        return stickerAntiguoMach;
      case "CUSTODIA BANCO BCI MACH STICKERS 4X4 CM":
        return stickerAntiguoMach;
      case "CUSTODIA BANCO BCI MACH CONTENEDOR 22,2X29,7 CM":
        return contenedorPrepagoMach;     
      case "TARJ. CUSTODIA VISA MACH DI BANCO BCI":
        return tarjetaPrepagoMach;  
      case "TARJ. CUSTODIA VISA MACH DEBITO DI BANCO BCI":
        return tarjetaDebitoMach;                       
      case "PANES PARA PREESTAMPACION DE TARJETAS MACH DEBITO ADULTO":
        return tarjetaDebitoMach; 
      case "CUSTODIA BANCO BCI MACH DEBITO CONTENEDOR - NUEVO DISEÑO":
        return contenedorDebitoMach; 
      case "CUSTODIA BANCO BCI MACH SOBRE SACOS DRYLETTER":
        return sobreMach;  
      case "PANES PARA PREESTAMPACION - TARJETA TAPP PREPAGO":
        return tarjetaTappAzul;                       
      case "CUSTODIA - TARJETA DE PREPAGO TAPP PREESTAMPADA - MASTERCARD DI":
        return tarjetaTappAzul; 
      case "CUSTODIA - TRIPTICO MECANIZADO TAPP AZUL":
        return tripticoTapp; 
      case "CUSTODIA - SOBRE MECANIZADO TAPP AZUL":
        return sobreTapp;  
      case "CUSTODIA - TRIPTICO MECANIZADO TAPP AZUL - DESTACAME":
        return tripticoDestacame; 
      case "CUSTODIA - SOBRE MECANIZADO TAPP AZUL - DESTACAME":
        return sobreDestacame;             
      case "CUSTODIA - TARJETA DE PREPAGO DESTACAME BY TAPP - MASTERCARD DI":
        return tarjetaDestacame;  
      case "CUSTODIA - TARJETA DE PREPAGO DESTACAME BY TAPP PREESTAMPADA - MASTERCARD DI":
        return tarjetaDestacame; 
      case "PANES PARA PREESTAMPACION - TARJETA TAPP DESTACAME":
        return tarjetaDestacame;     
      case "CUSTODIA - STICKER MECANIZADO TAPP AZUL":
        return stickerTapp;                                            
      default:
        return imgStock;
    }
  };

  const fetchData = async () => {
    try {
      const data = await apiStock();
      if (data.error) {
      } else {
        setDataStock(data.ok[0]);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(); 
  }, []);

  const { downloadExcel, isLoadingDownload } = useDownloadExcel(dataStock, VISIBLE_FIELDS);
  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10); // Formatea la fecha como YYYY-MM-DD
    return formattedDate;
  };

  return (
    <div className="bg-gray-200 font-sans">
{showModal && (
  <>
    <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="w-auto my-12 max-h-screen-1/4">
        {/* content */}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
          {/* body */}
          <div className="relative p-6 flex-auto max-h-[500px] max-w-[800px] overflow-y-auto">
            <div className="flex items-center justify-center mb-2">
              <img
                src={getBinImage(selectedItem.Nombre)}
                alt="Imagen Stock"
                className="w-auto h-[200px] max-w-full"
              />
            </div>
            <div className="text-xs text-center">
              <div className="font-bold text-lg">
                {selectedItem.Nombre.includes("PORTA TARJETAS") ? (
                  <div>Porta Tarjetas</div>
                ) : selectedItem.Nombre.includes(
                    "TARJETA CMR THALES DEBITO"
                  ) ? (
                  <div>Tarjeta Débito</div>
                ) : selectedItem.Nombre.includes(
                    "TARJETA CMR IDEMIA CREDITO"
                  ) ? (
                  <div>Tarjeta Crédito</div>
                ) : (
                  <div>{selectedItem.Nombre}</div>
                )}
              </div>
              <div className="mt-1 text-blue-700 opacity-80">
                {`VIRGEN - ${selectedItem.Virgen} UNIDADES`}
              </div>
              <div className="mt-1 text-blue-700 opacity-80">
                {`RECHAZADA - ${selectedItem.Rechazada} UNIDADES`}
              </div>
              <div className="mt-1 text-blue-700 opacity-80">
                {`FALLIDA - ${selectedItem.Fallida} UNIDADES`}
              </div>
              <div className="px-20">
                {selectedItem.Bin
                  ? `${selectedItem.Bin} - ${selectedItem.Nombre}`
                  : selectedItem.Nombre}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              className="bg-red-500 text-white rounded-lg background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => closeModal()}
            >
              Salir
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
  </>
)}


      <Navbar />
      {loading && <Loader />}
      <div className="flex items-start justify-left p-8 px-6 w-2/4">
        <div className="flex justify-between items-center">
          <div className="text-xl font-semibold text-black m-2 mb-2 row-span-1 ">
            <h1 className="[text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
              {" "}
              En Stock {dataStock.length > 0 && `(${dataStock[0].Fecha})`}
            </h1>
          </div>
        </div>
      </div>


      <div className="flex items-start justify-center p-6">
  <div className="rounded-lg p-6 bg-white w-full">

      <div className="flex items-end justify-end pl-6 py-4 pr-2"> {/* Cambié 'justify-right' por 'justify-end' para alinear a la derecha */}
  <button
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" // Modifiqué 'py-2 px-4' por 'py-1 px-2' para hacer el botón más pequeño
    disabled={isLoadingDownload} // Disable button while downloading
    onClick={() => downloadExcel(`Stock_${getCurrentDate()}`)} // Call downloadExcel function with dynamic file name
    >
    {isLoadingDownload ? "Descargando..." : "Descargar Excel"}
  </button>
</div>
        <DataGrid
          rows={dataStock}
          columns={VISIBLE_FIELDS}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slots={{ toolbar: GridToolbar }}
          pageSizeOptions={[10, 25, 50]}
          disableDensitySelector={true}
          density="compact"
          getRowClassName={(params) => {
            const isEven = params.indexRelativeToCurrentPage % 2 === 0;
            return isEven ? "table-row-even" : "table-row-odd";
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            },
          }}
          sx={{
            backgroundColor: "white", 
            p: 1, 
            m: 0.45,
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              fontSize: 13,
              backgroundColor: "#00205C",
              color: "white",
            },
            "& .table-row-even .MuiDataGrid-cell": {
              backgroundColor: "#EAF9FF", 
            },
          }}
        />
      </div>
</div>
      <Footer />
    </div>
  );
};

export default Stock;
