import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import LayoutLogin from "./Routes/Login/Login";
import Menu from "./Routes/Menu/Menu";
import Seguimiento from "./Routes/Seguimiento/Seguimiento";
import Reportes from "./Routes/Reportes/Reportes";
import Tarjetas from "./Routes/Tarjetas/Tarjetas";
import Stock from "./Routes/Stock/Stock";
import CambioClave from "./Routes/CambioClave/CambioClave";
import Protected from "./Authentication/ProtectedRoute";
import NotFound from "./Routes/Error/RutaNoEncontrada";  

function App() {
  return (
    <BrowserRouter>
      <Toaster position="top-center" />
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace/>}  />
        <Route
          path="/Login"
          element={<LayoutLogin title={"AMF Seguridad - Iniciar Sesión"}/> }
        />
        <Route
          path="/Menu"
          element={<Protected>  <Menu title={"AMF Seguridad - Menu"} /> </Protected>}
        />
        <Route
          path="/Seguimiento"
          element={<Protected>  <Seguimiento title={"AMF Seguridad - Seguimiento"} /> </Protected>}
        />
        <Route
          path="/Reportes"
          element={<Protected>  <Reportes title={"AMF Seguridad - Reportes"} /> </Protected>}
        />
        <Route
          path="/Tarjetas"
          element={<Protected>  <Tarjetas title={"AMF Seguridad - Tarjetas"} /> </Protected>}
        />
        <Route
          path="/Stock"
          element={<Protected>  <Stock title={"AMF Seguridad - Stock"} /> </Protected>}
        />
          <Route
          path="/CambioClave"
          element={<Protected>  <CambioClave title={"AMF Seguridad - Cambiar Contraseña"} /> </Protected>}
        />
      <Route path="*" element={<Protected> <NotFound title={"AMF Seguridad - Error"}/> </Protected>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
