import React, { useEffect, useState} from "react";
import { reporteTarjetas, reporteRegionesTarjetas } from "./ApiTarjetas";
import "tailwindcss/tailwind.css";
import { useLocation } from "react-router-dom";
import Navbar from "../../Layouts/Navbar/Navbar";
import Footer from "../../Layouts/Footer";
import { DataGrid, GridToolbar, esES  } from '@mui/x-data-grid';
import Loader from "../../Components/Cargando";
import "./Tarjetas.css"; 
import TrackingSeguimiento from "../Seguimiento/TrackingSeguimiento";
import apiIdTarjeta from "../Seguimiento/ApiIdTarjeta";
import { FaMapMarkerAlt } from "react-icons/fa";
import pLimit from 'p-limit';
import Map from './Mapa';
import toast from "react-hot-toast";
import  useDownloadExcel  from "./useDownloadExcel";


const Tarjetas = ({ title }) => {
  const location = useLocation();
  useEffect(() => {
    handleFormSubmit();
    document.title = title;
  }, [location, title]);

  const limit = pLimit(1);
  const [showTable, setShowTable] = useState(false);
  const [filteredRegion, setFilteredRegion] = useState('');
  const handleRegionClick = nombreRegion => {
    setFilteredRegion(nombreRegion);
    setShowTable(true);    
  };




  
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [dataRegiones, setDataRegiones] = useState([]);

  const handleFormSubmit = async () => {
    try {
      const data = await limit(() => reporteTarjetas());
  
      if (data.error) {
        toast.error(data.error);
      } else {
        setReportData(data.ok);
      }
        const datagrafico = await limit(() => reporteRegionesTarjetas());
  
      if (datagrafico.error) {
            toast.error(data.error);
      } else {
        setDataRegiones(datagrafico.ok[0]);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  

    const VISIBLE_FIELDS = [
      { field: 'id', headerName: 'ID', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center' },
      { field: 'CodigoSeguimiento', headerName: 'CodigoSeguimiento', flex: 1, type: 'number', headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center' },
      { field: 'FechaCreacion', headerName: 'FechaEstado', flex: 1, type:'Date', headerClassName: 'super-app-theme--header'  ,headerAlign: 'center', align: 'center'},
      { field: 'Nombre', headerName: 'Nombre', flex: 1, headerClassName: 'super-app-theme--header'  ,headerAlign: 'center', align: 'center'},
      { field: 'Direccion', headerName: 'Direccion', flex: 1, headerClassName: 'super-app-theme--header'  ,headerAlign: 'center', align: 'center'},
      { field: 'Comuna', headerName: 'Comuna', flex: 1, headerClassName: 'super-app-theme--header'  ,headerAlign: 'center', align: 'center'},
      { field: 'NombreEstadoCorreo', headerName: 'EstadoCorreo', flex: 1,type:'string', headerClassName: 'super-app-theme--header'  ,headerAlign: 'center',align: 'center'},
      { field: 'Oficina', headerName: 'Oficina', flex: 1,type:'string', headerClassName: 'super-app-theme--header'  ,headerAlign: 'center',align: 'center'},
      { field: 'TipoTarjeta', headerName: 'TipoTarjeta', flex: 1, headerClassName: 'super-app-theme--header'  ,headerAlign: 'center',align: 'center'},
      {
        field: 'Ver',
        headerName: 'Ver',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const idTarjeta = params.id; 
          return (
            <div className="flex items-center justify-center p-2 text-l font-bold">
            <FaMapMarkerAlt className="text-xl mb-1 mr-2 text-blue-500 inline-block" />
            <button  className="text-blue-500 hover:text-blue-300" onClick={() => openModal(idTarjeta)}>
              Ver
            </button>
          </div>

          );
        },
      },
    ];
    const [showModal, setShowModal] = useState(false);
    const [seguimientoTarjeta, setSeguimientoTarjeta] = useState ([]);
    const openModal = async (idTarjeta) =>  {
      setLoading(true);
      const data = await apiIdTarjeta(idTarjeta);
      setSeguimientoTarjeta(data.ok);
      setShowModal(true);
      setLoading(false);

    };

    
    
    const closeModal = () => {
      setShowModal(false);
    };

    const { downloadExcel, isLoadingDownload } = useDownloadExcel(reportData, VISIBLE_FIELDS);
    const getCurrentDate = () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10); // Formatea la fecha como YYYY-MM-DD
      return formattedDate;
    };

  return (
<div>

    <div className="bg-gray-200 font-sans">




{showModal ? (
  <>
<div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="w-auto my-12 max-h-screen-1/4">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
          {/*body*/}
          <div className="relative p-6 flex-auto  max-h-[500px] max-w-[1300px] overflow-y-auto">
            {loading ? (
              <Loader />
            ) : (
              <TrackingSeguimiento trackingSeguimiento={seguimientoTarjeta} />
            )}
          </div>
          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
          <button
              className="bg-red-500 text-white rounded-lg background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => closeModal()}
            >
              Salir
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
  </>
) : null}


      <Navbar />
      {loading && <Loader />}


      <div className="flex items-start justify-left p-8">
      <div className="rounded-lg p-6 w-2/4">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
          <div className="text-lg font-semibold text-black m-2 mb-2 row-span-1 ">
          <h1 className="[text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">En Sucursal</h1></div>
          <div className="text-sm text-black m-2 mb-2 row-span-1">
          <h1 className="[text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
           Información de las tarjetas que se encuentran en sucursal y en la planta de destino en los ultimos 15 días.
           </h1>
           </div>
           </div>
        </div>
        </div>
        
    </div>


    <div className="flex items-start justify-center p-6">
      <div className="rounded-lg p-6 bg-white" >
          {dataRegiones && (
            <Map
              dataregiones={dataRegiones}
              handleRegionClick={handleRegionClick}
            />
          )}
<div className="flex items-end justify-end pl-6 py-4 pr-2"> {/* Cambié 'justify-right' por 'justify-end' para alinear a la derecha */}
  <button
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" // Modifiqué 'py-2 px-4' por 'py-1 px-2' para hacer el botón más pequeño
    disabled={isLoadingDownload} // Disable button while downloading
    onClick={() => downloadExcel(`Tarjetas_Sucursal_${getCurrentDate()}`)} // Call downloadExcel function with dynamic file name
    >
    {isLoadingDownload ? "Descargando..." : "Descargar Excel"}
  </button>
</div>

{showTable && (
  <div className="">
    {loading ? (
      <Loader />
    ) : (
      <DataGrid
        autoHeight={true}
        rows={reportData.filter((row) => row.Region === filteredRegion)}
        columns={VISIBLE_FIELDS}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        slots={{ toolbar: GridToolbar }}
        pageSizeOptions={[10, 25, 50]}
        disableDensitySelector={true}
        density="compact"
        getRowClassName={(params) => {
          const isEven = params.indexRelativeToCurrentPage % 2 === 0;
          return isEven ? 'table-row-even' : 'table-row-odd';
        }}
        localeText={
          esES.components.MuiDataGrid.defaultProps.localeText
        }
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
          },
        }}
        sx={{
          p: 1,
          m: 0.45,
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .super-app-theme--header': {
            fontSize: 13,
            backgroundColor: '#00205C',
            color: 'white',
          },
          '& .table-row-even .MuiDataGrid-cell': {
            backgroundColor: '#EAF9FF', 
          },
        }}
      />
    )}
  </div>
)}
    </div>
    </div>


      <Footer />
    </div>
    </div>
  );
};

export default Tarjetas;
