import React, { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import loginImg from "../../Assets/images/img_login.png";
import AmfLogo from "../../Assets/images/amf_seg_logo.svg";
import { useLocation } from "react-router-dom";
import toast from 'react-hot-toast';
import Api from './ApiLogin';
import { FaQuestionCircle } from "react-icons/fa";

const LoginForm = ({ title }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = title;
  }, [location, title]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setIsButtonDisabled(!(username.trim() !== "" && password.trim() !== ""));
  }, [username, password]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const attemptLogin = (retry = false) => {
        Api(username, password)
            .then((data) => {
                if (data.error) {
                    if (!retry) {
                        setTimeout(() => attemptLogin(true), 5000);
                    } else {
                        toast.error(data.error);
                    }
                } else {
                    localStorage.setItem('CodEmisor', data.ok[0].CodEmisor);
                    localStorage.setItem('Usuario', username);
                    localStorage.setItem('JWT', data.token);
                    toast.success("Inicio de sesión exitoso");
                    setTimeout(() => {
                        if (data.ok[0].CambioClave) {
                            window.location.href = "/cambioclave";
                        } else {
                            window.location.href = "/seguimiento";
                        }
                    }, 1000);
                }
            })
            .catch((error) => {
                if (!retry) {
                    console.log("First attempt failed, retrying login in 5 seconds...");
                    setTimeout(() => attemptLogin(true), 5000);
                } else {
                    toast.error(error.message);
                    console.error("Error:", error);
                }
            })
            .finally(() => {
                if (retry) {
                    setIsLoading(false);
                }
            });
    };

    attemptLogin();
};

  return (
    <div className="flex flex-row bg-gray-200">
        <img className="w-[720px] p-2 h-screen" src={loginImg} alt="" />

      <div className="w-2/3  bg-gray-200 rounded-lg flex flex-col justify-center">
        <form className=" mx-auto bg-white p-4 max-w-[425px] " onSubmit={handleFormSubmit}>
          <div className="text-center py-6">
            <img className="pb-2 w-2/4 h-full object-cover" src={AmfLogo} alt="" />
          </div>
          <div className="flex flex-col px-5 py-3">
  <label className="py-4">Ingresa tu usuario aquí</label>
  <input
    type="text"
    className="w-full h-12 bg-white p-4 text-base font-semibold outline-0 border-2 border-sky-400 rounded-full focus:outline-none focus:border-blue-700"    placeholder="Tu correo"
    maxLength={120}
    value={username}
    onChange={(e) => setUsername(e.target.value)}
    onFocus={(e) => (e.target.placeholder = "")}
    onBlur={(e) => (e.target.placeholder = "Tu usuario")}
  />
</div>

          <div className="flex flex-col px-5 py-3">
          <label className="py-4">Ingresa tu contraseña aquí</label>
            <input
                  className="w-full h-12 bg-white pl-2 text-base font-semibold outline-0 border-2 border-sky-400 rounded-full focus:outline-none focus:border-blue-700"                  placeholder="Tu contraseña"
                  maxLength={120}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) =>
                    (e.target.placeholder = "Tu contraseña ")
                  }
                />
          </div>


          <div className="flex items-center mb-2 py-2 px-5">
            <FaQuestionCircle className="mr-2 text-blue-500" />
            <span> Olvidaste tu contraseña</span>
          </div>
<div className="flex justify-center">
            <button
              className={`border w-1/2 my-5 py-2 rounded-full ${isButtonDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500'} text-white`}
              type="submit"
              disabled={isButtonDisabled}
            >
            {isLoading ? "Cargando..." : "Ingresar"}
          </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
