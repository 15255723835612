import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "tailwindcss/tailwind.css";
import { useLocation } from "react-router-dom";
import Navbar from "../../Layouts/Navbar/Navbar";
import Footer from "../../Layouts/Footer";

const Menu = ({ title }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = title; 
  }, [location, title]);
  return (
    <div>
      <Navbar />
      <div
        className="px-3 md:lg:xl:px-40   border-t border-b py-20 bg-opacity-10"
        src="https://www.toptal.com/designers/subtlepatterns/uploads/dot-grid.png"
      >
        <div className="grid grid-cols-1 md:lg:xl:grid-cols-2 group bg-white shadow-xl shadow-neutral-100 border ">
          <Link
              to="/seguimiento"  className="p-10 flex flex-col items-center text-center group    md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
            <div
              className="p-5 rounded-full bg-teal-500 text-white shadow-lg shadow-teal-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
              </svg>
              </div>
            <p className="text-xl font-medium text-slate-700 mt-3">
              Seguimiento
            </p>
            <p className="mt-2 text-sm text-slate-500">
              Ver el estado de una tarjeta despachada desde AMF.
            </p>
            </Link>
            <Link
              to="/reportes" className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
            <div
              className="p-5 rounded-full bg-lime-500 text-white shadow-lg shadow-lime-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                />
              </svg>
            </div>
            <p className="text-xl font-medium text-slate-700 mt-3">Reportes</p>
            <p className="mt-2 text-sm text-slate-500">
              Información anual o mensual de las tarjetas realizadas en AMF.
            </p>
          </Link>
          <Link
              to="/tarjetas"  className="p-10 flex flex-col items-center text-center group    md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
            <div
              className="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-teal-200"
            >
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M8 8v1h4V8m4 7H4a1 1 0 0 1-1-1V5h14v9a1 1 0 0 1-1 1ZM2 1h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z"/>
            </svg>
              </div>
            <p className="text-xl font-medium text-slate-700 mt-3">
              En Sucursal
            </p>
            <p className="mt-2 text-sm text-slate-500">
              Tarjetas ubicadas en planta y en sucursal en los ultimos 15 dias.
            </p>
            </Link>
            <Link
              to="/stock"  className="p-10 flex flex-col items-center text-center group    md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
            <div
              className="p-5 rounded-full bg-yellow-500 text-white shadow-lg shadow-teal-200"
            >
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M8 8v1h4V8m4 7H4a1 1 0 0 1-1-1V5h14v9a1 1 0 0 1-1 1ZM2 1h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z"/>
            </svg>
              </div>
            <p className="text-xl font-medium text-slate-700 mt-3">
              Stock
            </p>
            <p className="mt-2 text-sm text-slate-500">
              Tarjetas.
            </p>
            </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Menu;
