import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import loginImg from "../../Assets/images/img_login.png";
import AmfLogo from "../../Assets/images/amf_seg_logo.svg";
import toast from "react-hot-toast";
import ApiCambioClave from "./ApiCambioClave";
import {  FaTimesCircle, FaCheckCircle } from "react-icons/fa";

const CambioClave = ({ title }) => {
  const location = useLocation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [condicionLargo, setCondicionLargo] = useState(false);
  const [condicionNumero, setCondicionNumero] = useState(false);
  const [condicionCaracterEsp, setCondicionCaracterEsp] = useState(false);
  const [condicionMayus, setCondicionMayus] = useState(false);
  const [condicionContraSimilar, setCondicionContraSimilar] = useState(false);
  const [conditionsMet, setConditionsMet] = useState(false);

  useEffect(() => {
    document.title = title;
  }, [location, title]);

  useEffect(() => {
    const isLengthValid = newPassword.length > 6;
    const hasDigits = /\d/.test(newPassword);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
    const hasUppercase = /[A-Z]/.test(newPassword);
    const claveSimilar = newPassword === confirmPassword && newPassword !== "";

    setCondicionLargo(isLengthValid);
    setCondicionNumero(hasDigits);
    setCondicionCaracterEsp(hasSpecialChars);
    setCondicionMayus(hasUppercase);
    setCondicionContraSimilar(claveSimilar);

    const timeoutId = setTimeout(() => {
      setConditionsMet(
        isLengthValid &&
        hasDigits &&
        hasSpecialChars &&
        hasUppercase &&
        claveSimilar
      );
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [newPassword, confirmPassword]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    ApiCambioClave(newPassword, oldPassword)
      .then((data) => {
        if (data.error) {
          toast.error(data.error);
        } else if (data.ok[0][""]) {
          toast.success("Contraseña cambiada correctamente.");
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/login";
          }, 2000);
        } else {
          toast.error("La contraseña temporal no coincide.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
      });
  };

  return (
    <div className="flex flex-row bg-gray-200">
      <img className="w-[720px] p-2 h-screen" src={loginImg} alt="" />

      <div className="w-2/3  bg-gray-200 flex flex-col justify-center">
        <form className=" mx-auto bg-white p-4 max-w-[425px] " onSubmit={handleFormSubmit}>
          <div className="text-center py-6">
            <img className="pb-2 w-2/4 h-full object-cover" src={AmfLogo} alt="" />
          </div>
          <div className="flex flex-col px-5 py-3">
            <label className="py-4">Ingresa tu contraseña actual</label>
            <input
              type="password"
              className="w-full h-12 bg-white p-4 text-base font-semibold outline-0 border-2 border-sky-400 rounded-full focus:outline-none focus:border-blue-700"
              placeholder="Contraseña actual"
              maxLength={120}
              id="oldPassword"
              name="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Tu correo")}
            />
          </div>

          <div className="flex flex-col px-5 py-3">
            <label className="py-2">Ingresa tu nueva contraseña</label>
            <input
              className="w-full h-12 bg-white pl-2 text-base font-semibold outline-0 border-2 border-sky-400 rounded-full focus:outline-none focus:border-blue-700"
              placeholder="Nueva contraseña"
              maxLength={120}
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Tu contraseña ")}
            />
          </div>

          <div className="flex flex-col px-5 py-3">
            <label className="py-2">Repite tu nueva contraseña</label>
            <input
              className="w-full h-12 bg-white pl-2 text-base font-semibold outline-0 border-2 border-sky-400 rounded-full focus:outline-none focus:border-blue-700"
              placeholder="Nueva contraseña"
              maxLength={120}
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Tu contraseña ")}
            />
          </div>
          <div className="flex items-center mb-2 py-2 px-5">
            {condicionLargo ? (
              <FaCheckCircle className="mr-2 text-green-500" />
            ) : (
              <FaTimesCircle className="mr-2 text-red-500" />
            )}
            <span> Debe tener más de 6 caracteres</span>
          </div>

          <div className="flex items-center mb-2 py-2 px-5">
            {condicionNumero ? (
              <FaCheckCircle className="mr-2 text-green-500" />
            ) : (
              <FaTimesCircle className="mr-2 text-red-500" />
            )}
            <span> Incluye dígitos</span>
          </div>

          <div className="flex items-center mb-2 py-2 px-5">
            {condicionMayus ? (
              <FaCheckCircle className="mr-2 text-green-500" />
            ) : (
              <FaTimesCircle className="mr-2 text-red-500" />
            )}
            <span> Incluye mayúsculas</span>
          </div>

          <div className="flex items-center mb-2 py-2 px-5">
            {condicionCaracterEsp ? (
              <FaCheckCircle className="mr-2 text-green-500" />
            ) : (
              <FaTimesCircle className="mr-2 text-red-500" />
            )}
            <span> Incluye caracteres especiales</span>
          </div>

          <div className="flex items-center mb-2 py-2 px-5">
            {condicionContraSimilar ? (
              <FaCheckCircle className="mr-2 text-green-500" />
            ) : (
              <FaTimesCircle className="mr-2 text-red-500" />
            )}
            <span> Las contraseñas son similares</span>
          </div>
          <div className="flex justify-center">
            <button
              className={`border w-1/2 my-5 py-2 rounded-full ${conditionsMet ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-gray-300 cursor-not-allowed'} text-white`}
              type="submit"
              disabled={!conditionsMet}
            >
              Cambiar Contraseña
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CambioClave;
